<template>
	<div style="height: 100vh;overflow: auto;">
		<div class="dialog-box">
			<el-form :model="formData" :rules="rules" ref="productForm">
				<el-row :gutter="20">
					<el-col :sm="20" :md="item.showType == 'editor'?20:14" v-for="(item, index) in formDataConfig" v-bind:key="index">
						<el-form-item :label="item.label" :prop="item.prop" :label-width="formLabelWidth">
							<el-input
								v-if="item.showType == 'textarea'"
								:disabled="item.disabled"
								:readonly="item.readOnly"
								v-model="formData[item.prop]"
								autocomplete="off"
								type="textarea"
								:placeholder="'请输入' + item.label"
							></el-input>

							<el-switch v-else-if="item.showType == 'swiper'" :disabled="item.disabled" v-model="formData[item.prop]"></el-switch>

							<el-input
								v-else-if="item.showType == 'number'"
								type="number"
								:disabled="item.disabled"
								:readonly="item.readOnly"
								v-model="formData[item.prop]"
								auto-complete="off"
								:placeholder="'请输入' + item.label"
							></el-input>

							<el-radio-group v-else-if="item.showType == 'radio'" :disabled="item.disabled" v-model="formData[item.prop]">
								<el-radio v-for="(ritem, rindex) in item.option" :label="ritem" v-bind:key="rindex"></el-radio>
							</el-radio-group>
							<avue-ueditor v-else-if="item.showType == 'editor'" v-model="formData[item.prop]" :options="options" :id="'editor_id_' + opentype + item.prop" prop="item.prop"></avue-ueditor>
							<el-date-picker
								v-else-if="item.showType == 'date'"
								v-model="formData[item.prop]"
								:disabled="item.disabled"
								:type="item.showType"
								placeholder="选择日期"
								:picker-options="pickerOptions"
							></el-date-picker>

							<el-select v-else-if="item.showType == 'select'" :disabled="item.disabled" v-model="formData[item.prop]" placeholder="请选择">
								<el-option
									v-for="(opitem, opindex) in item.option"
									:key="opindex"
									:label="item.lkey ? opitem[item.lkey] : opitem.label"
									:value="item.vkey ? opitem[item.vkey] : opitem.value"
								></el-option>
							</el-select>
							<el-select v-else-if="item.showType == 'mSelect'" v-model="formData[item.prop]" multiple placeholder="请选择">
								<el-option
									v-for="(opitem, opindex) in item.option"
									:key="opindex"
									:label="item.lkey ? opitem[item.lkey] : opitem.label"
									:value="item.vkey ? opitem[item.vkey] : opitem.value"
								></el-option>
							</el-select>
							<el-cascader
								v-else-if="item.showType == 'cascader'"
								:disabled="item.disabled"
								v-model="formData[item.prop]"
								:options="item.option"
								:props="{ expandTrigger: 'hover' }"
							></el-cascader>

							<ImgUpload
								v-else-if="item.showType == 'imgUploader'"
								@oploadBack="successBack"
								@delcallback="delImgBack"
								:imgurl="uploadImgData"
								:items="item"
							></ImgUpload>

							<multipleImgUploader
								v-else-if="item.showType == 'mImgUploader'"
								@oploadBack="successBack"
								@delcallback="delImgBack"
								:imgurl="muploadImgData"
								:items="item"
							></multipleImgUploader>

							<el-input
								v-else-if="item.showType == 'password'"
								:disabled="item.disabled"
								:readonly="item.readOnly"
								v-model="formData[item.prop]"
								autocomplete="off"
								:placeholder="'请输入' + item.label"
								show-password
							></el-input>
							<div v-else-if="item.showType == 'form'"><component v-bind:is="formComponentName" :selectdata="formData[item.prop]"></component></div>
							<el-input
								v-else
								:readonly="item.readOnly"
								:disabled="item.disabled"
								v-model="formData[item.prop]"
								autocomplete="off"
								:placeholder="'请输入' + item.label"
							></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-tabs v-model="activeName" @tab-click="handleClick" style="margin-left: 60px;margin-right: 60px;">
				<el-tab-pane v-if="opentype==1" label="默认规格" name="default">
					<DefaultSku :item="skuObj" @getSkuData="getSku"></DefaultSku>
				</el-tab-pane>
				<el-tab-pane label="新增规格" name="new">
					<NewSku @getSkuData="getSku"></NewSku>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="btnArea">
			<el-button size="small" @click="handleChangeClose">关 闭</el-button>
			<el-button size="small" type="custom" @click="handleChangeConfirm">确 定</el-button>
		</div>
	</div>
</template>

<script>
import { formLabelConfig } from '../../assets/config/store/ProductList.js';
const baseUrl = process.env.VUE_APP_API=="/"?'/api':process.env.VUE_APP_API
export default {
	props: {
		opentype: {
			//类型判断0自定义 1为新建，2为编辑，3为查看
			type: Number,
			default: 0
		},
		selectdata: {
			type: Object,
			default: () => {}
		},
		formConfig: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			formLabelWidth: '140px',
			formDataConfig: formLabelConfig, //表单label配置
			standardLabel: [],
			uploadImgData: '',
			muploadImgData: '',
			uploadImg: '',
			skuType:"default",
			oploadFilds: '', //图片字段
			formData: {},
			defaultSku:{},
			skuObj:{},
			rules: {},
			activeName: 'default',
			text:"",
			options:{
				action: baseUrl+"/file-upload/upload",
				props: {
					res: "data",
					url:'url'
				},
			}
		};
	},
	components: {
		ImgUpload: () => import('@/components/imgUpload/imgUploader.vue'),
		multipleImgUploader: () => import('@/components/imgUpload/multipleImgUploader.vue'),
		DefaultSku:()=> import('@/components/businessComponent/defaultSku.vue'),
		NewSku:()=> import('@/components/businessComponent/newSku.vue'),
	},
	created() {
		this.getTagsList();
		this.getCateGoryList();
		this.getTransportTplList();
		if (this.opentype == 2) {
			this.getDetail();
			this.activeName = "new"
		}
		if (this.opentype == 0 || this.opentype == 2 || this.opentype == 3) {
			this.formData = this.selectdata;
			this.formDataConfig.map(v => {
				if (v.showType == 'imgUploader') {
					this.uploadImgData = this.formData[v.prop];
				}
				if (v.showType == 'mImgUploader') {
					this.muploadImgData = this.formData[v.prop];
				}
			});
		}
		this.newFormVerification();
	},
	mounted() {
		console.log(this.selectdata);
	},
	methods: {
		handleClick() {
			
		},
		getSku(e){
			this.skuData = e[0]
			this.skuData.commodityName = this.formData.commodityName || ''
			// this.formData.commodityActualPirce = e[0].skuPrice
			// this.formData.commodityOriginalPrice = e[0].skuOriPrice
			// this.formData.commodityStock = e[0].skuActualStocks
		},
		//富文本编辑操作数据
		onContentChange(val, name) {
			this.$set(this.formData, name, val);
		},
		//关闭dialog
		handleChangeClose() {
			this.$emit('schange', false);
		},
		//表单验证
		newFormVerification() {
			for (let i = 0; i < this.formDataConfig.length; i++) {
				let field = this.formDataConfig[i];
				this.validationRules(field, this);
			}
		},
		//图片上传成功回调
		successBack(list, res, name, ismultiple) {
			if (ismultiple) {
				this.uploadShowList = list;
			} else {
				const arr = { uid: '', url: res.data };
				this.uploadShowList = [arr];
			}
			this.setUploadImgUrl(this.uploadShowList, name, ismultiple);
		},
		//删除图片回调
		delImgBack(res, name, ismultiple) {
			this.setUploadImgUrl(res, name, ismultiple);
		},
		//设置上传图片地址
		setUploadImgUrl(res, name, ismultiple) {
			let imgurls = '';
			if (res.length > 0) {
				res.forEach((item, index) => {
					if (imgurls != '') imgurls += ',';
					imgurls += item.url;
				});
			}
			this.uploadImg = imgurls;
			this.oploadFilds = name;
			// if(ismultiple)
			// 	this.muploadImgData = imgurls
			if (this.oploadFilds) this.$set(this.formData, this.oploadFilds, this.uploadImg);
		},
		//提交保存数据
		handleChangeConfirm() {
			//提交数据this.formData
			// console.log(this.formData)
			this.$refs['productForm'].validate(async valid => {
				if (valid) {
					let url = "/sys/commodity/save"
					if(this.opentype == 2){
						url = "/sys/commodity/update"
					}
					if(!this.skuData && this.opentype==1) return this.$message.success('请填写规格');
					if(this.skuData){
						this.skuData.commodityId = this.selectdata.commodityId
						this.formData.skuList = [this.skuData]
					}
					await this.$axios("POST", url, this.formData, true);
					this.$message.success('保存成功！');
					this.$emit('fchange', false);
				} else {
					this.$message.error('请完善表单信息后重试！');
				}
			});
		},
		validationRules(editData, thiz) {
			if (editData.allowNull == false) return;
			let editType,
				valOption,
				textEditRule = [];
			if (editData.testType) editType = editData.testType;
			if (editData.testTypeOption) valOption = editData.testTypeOption;
			switch (editType) {
				case 'number':
					textEditRule = [
						{
							required: true,
							validator: (rule, value, callback) => {
								if (!value && value != 0 && !editData.allowNull) {
									return callback(new Error(editData.label + '不能为空'));
								}
								let val = /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value);
								if (!val) {
									callback(new Error('请输入合法的数字'));
								} else if (valOption) {
									if ('min' in valOption) {
										if (value < valOption.min) callback(new Error('输入值不能小于' + valOption.min));
										else callback();
									} else if ('max' in valOption) {
										if (value > valOption.max) callback(new Error('输入值不能大于' + valOption.max));
										else callback();
									} else if ('range' in valOption) {
										if (value > valOption.range[1] || value < valOption.range[0])
											callback(new Error('输入值介于' + valOption.range[0] + '和' + valOption.range[1] + '之间'));
										else callback();
									} else callback();
								} else callback(new Error('请指定输入值的范围!'));
							},
							trigger: ['blur', 'change']
						}
					];
					break;
				case 'digits':
					textEditRule = [
						{
							required: true,
							validator: (rule, value, callback) => {
								if (!value && !editData.allowNull) {
									return callback(new Error(editData.label + '不能为空'));
								}
								if (!Number.isInteger(Number(value))) {
									callback(new Error('请输入整数'));
								} else if (valOption) {
									if ('min' in valOption) {
										if (value < valOption.min) callback(new Error('输入值不能小于' + valOption.min));
										else callback();
									} else if ('max' in valOption) {
										if (value > valOption.max) callback(new Error('输入值不能大于' + valOption.max));
										else callback();
									} else if ('range' in valOption) {
										if (value > valOption.range[1] || value < valOption.range[0])
											callback(new Error('输入值介于' + valOption.range[0] + '和' + valOption.range[1] + '之间'));
										else callback();
									} else callback();
								} else callback(new Error('请指定输入值的范围!'));
							},
							trigger: ['blur', 'change']
						}
					];
					break;
				case 'percent':
					textEditRule = [
						{
							required: true,
							validator: (rule, value, callback) => {
								if (!value && !editData.allowNull) {
									return callback(new Error(editData.label + '不能为空'));
								}
								let reg = /^((\d+\.?\d*)|(\d*\.\d+))\%$/;
								if (!reg.test(value)) {
									callback(new Error('请输入百分比'));
								} else {
									callback();
								}
							},
							trigger: 'blur'
						}
					];
					break;
				case 'mobile':
					textEditRule = [
						{
							required: true,
							validator: (rule, value, callback) => {
								let length = value.length,
									mobile = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[8-9]{1}))+\d{8})$/; //验证手机
								if (!value && !editData.allowNull) {
									return callback(new Error(editData.label + '不能为空'));
								}
								if (length == 11 && mobile.test(value)) {
									callback();
								} else {
									return callback(new Error('手机号码格式错误！'));
								}
							},
							trigger: 'blur'
						}
					];
					break;
				case 'phone':
					textEditRule = [
						{
							required: true,
							validator: (rule, value, callback) => {
								let length = value.length,
									phone = /^0\d{2,4}-?\d{7,8}$/, //验证固定电话
									salephone = /^((400)|(800))[0-9]{7}$/;
								if (!value && !editData.allowNull) {
									return callback(new Error(editData.label + '不能为空'));
								}
								if ((length >= 11 && length <= 13 && phone.test(value)) || (length == 10 && salephone.test(value))) {
									callback();
								} else {
									return callback(new Error('电话号码格式错误！（固定电话格式“区号-电话”）'));
								}
							},
							trigger: 'blur'
						}
					];
					break;
				case 'email':
					textEditRule = [
						{
							required: true,
							validator: (rule, value, callback) => {
								let emReg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
								if (!value && !editData.allowNull) {
									return callback(new Error(editData.label + '不能为空'));
								}
								if (emReg.test(value)) {
									callback();
								} else {
									return callback(new Error('请输入正确的邮箱格式！'));
								}
							},
							trigger: 'blur'
						}
					];
					break;
				case 'postal':
					textEditRule = [
						{
							required: true,
							validator: (rule, value, callback) => {
								let ptlReg = /^[a-zA-Z0-9 ]{3,12}$/g;
								if (!value && !editData.allowNull) {
									return callback(new Error(editData.label + '不能为空'));
								}
								if (ptlReg.test(value)) {
									callback();
								} else {
									return callback(new Error('请输入正确的邮政编码！'));
								}
							},
							trigger: 'blur'
						}
					];
					break;
				default:
					textEditRule = [
						{
							required: true,
							message:((editData.showType=='imgUploader' || editData.showType=='mImgUploader')?'请上传':
							(editData.showType=='mSelect' || editData.showType=='select')?'请选择':
							editData.showType=='editor'?'请编辑':'请输入') + editData.label,
							trigger: ['blur', 'change']
						}
					];
					break;
			}
			thiz.$set(thiz.rules, editData.prop, textEditRule);
		},
		async getCateGoryList() {
			const res = await this.$axios('GET', '/sys/category/listCategory', {}, true);
			this.formDataConfig.map(v => {
				if (v.prop == 'categoryId') {
					v.option = res;
					v.lkey = 'categoryName';
					v.vkey = 'categoryId';
				}
			});
			this.$forceUpdate();
		},
		async getTransportTplList() {
			const res = await this.$axios('GET', '/sys/transport/list', {}, true);
			this.formDataConfig.map(v => {
				if (v.prop == 'deliveryTemplateId') {
					v.option = res;
					v.lkey = 'transName';
					v.vkey = 'transportId';
				}
			});
			this.$forceUpdate();
		},
		async getTagsList() {
			const res = await this.$axios('POST', '/sys/label/list', {}, true);
			this.formDataConfig.map(v => {
				if (v.prop == 'labelId') {
					v.option = res;
					v.lkey = 'labelName';
					v.vkey = 'labelId';
				}
			});
			this.$forceUpdate();
		},
		async getStandardsList() {
			const res = await this.$axios(
				'POST',
				`/sys/prodProp/list`,
				{
					pageNum: 1,
					pageSize: 1000
				},
				true
			);
			this.standardLabel = res;
		},
		async getDetail() {
			const res = await this.$axios('POST', '/sys/commodity/detail', { commodityId: this.selectdata.commodityId }, true);
			const {skuList,labelList } = res;
			this.formData = res;
			this.formData.labelId = labelList;
			this.skuObj = skuList[0] || {}
		}
	}
};
</script>

<style lang="scss" scoped>
	.dialog-box{
		max-height: 68vh !important;
		overflow: hidden;
	}
</style>
